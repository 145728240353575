.ContentPart, .ContentPart * {
    color: #fff !important;
}
.titleBlock2 .titleText, .blockMagazineTalk .titleText_2 {
    text-transform: uppercase;
}

#footerAcQuy .footerLine4Col{
    background: url(/builds/images/background_footer_right.png) right bottom no-repeat, url(/builds/images/background_footer_left.png) left top no-repeat;
    background-color: #1d428a;
}
#menuAcQuyDesk .menuLine2 .phonePart {
    font-size: 18px!important;
    padding-left: 25px!important;
}
#menuAcQuyDesk .menuLine2 .phonePart:before {
    font-size: 25px!important;
}
#menuAcQuyDesk .menuLine3 .categoryPart .subCatePart1 ul li a.liLevel1 {
    text-transform: uppercase;
}
.fixed-hotline {
    display: none;
    top: auto !important;
    left: 10%;
    right: 10%;
    height: 35px;
    min-width: 35px;
    bottom: 98px;
    border-radius: 20px;
    position: fixed;
    background: var(--xanh_chu_dao);
    border: solid 1px #fff;
    /*margin-right: -97px;*/
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    z-index: 9999;
    text-align: center;
}
.fixed-hotline a {
    display: block;
    color: #fff;
    position: relative;
}
.fixed-hotline .fa-phone {
    color: #fff;
    font-size: 25px;
    margin: 5px 5px;
    position: absolute;
    left: 0;
    top: 0;
}

.fixed-hotline .fixed-number {
    display: inline-block;
    font-size: 20px;
    line-height: 33px;
    margin-right: 15px;
    margin-left: 15px;
    font-weight: bold;
}
@media (max-width: 767px) {
    .fixed-hotline {
        display: block;
    }
}
